<template>
  <div
    class="flex-nowrap fullPanelContainer m-0"
    :class="{'leftPanelIfullPanelContainer': safari === false, 'fullPanelContainerSafari': safari }"
  >
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FullPanelContainer',
  computed: {
    ...mapGetters(['safari'])
  }
}

</script>

<style>
.fullPanelContainer {
  background: var(--bg-color);
  height: calc(100dvh);
  overflow-y: auto;
}
.fullPanelContainerSafari {
  background: var(--bg-color);
  height: calc(100vh);
  overflow-y: auto;
}
</style>
